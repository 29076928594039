<template>
  <div class="selected-case">
    <ul>
      <router-link
        v-for="(item, index) in caseList"
        :key="index"
        :to="{ path: '/2021/course', name: 'OneCourse', query: { id: item.courseid } }"
      >
        <div class="content">
          <!-- <img :src="JSON.parse(item.source).thumbnailUrl" alt="" /> -->
          <p class="name">{{item.teacher[0].name}}</p>
          <p class="company">{{item.teacher[0].company}}<br />{{item.teacher[0].position}}</p>
        </div>
        <p class="case-title">
          {{ item.title }}
        </p>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  props:{
    caseList: Array
  },
  data() {
    return {
    };
  },
  mounted() {
    // console.log(this.caseList);
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scpoed>
.selected-case {
  width: 1202px;
  margin: 0 auto;
  padding-top: 60px;
  border: 1px solid #edf5fc;
  border-top: none;
  border-bottom: none;
  ul {
    width: 1202px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 43px;
    margin-bottom: 0;
    a:hover {
      box-shadow: 0px 0px 8px 0px rgba(72, 75, 77, 0.1);
    }
    a {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 24px;
      margin-bottom: 25px;
      padding: 22px 22px 21px 20px;
      text-decoration: none;
      text-align: left;
      background: #ffffff;
      border: 1px solid #d6dce3;
      .case-title {
        width: 305px;
        height: 60px;
        padding-left: 7px;
        padding-bottom: 10px;
        line-height: 25px;
        font-weight: 500;
        font-size: 18px;
        font-family: PingFang SC;
        color: #003177;
      }
      .content {
        border-top: 1px solid #d6dce3;
        padding-top: 36px;
        padding-left: 9px;
        width: 340px;
        img {
          width: 69px;
          height: 13px;
        }
        .name {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #484848;
          margin-bottom: 6px;
        }
        .company {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          color: #3e3a39;
          line-height: 16px;
          height: 48px;
        }
      }
    }
    a:nth-child(3n) {
      margin-right: 0;
    }
  }
}
@media (max-width: 1023px) {
  .selected-case {
    width: 100vw;
    border: none;
    padding-top: 26px;
    ul {
      width:100%;
      flex-direction: column;
      padding-left: 7vw;
      padding-right: 7vw;
      padding-bottom: 14px;
      a {
        width: 100%;
        margin-right: 0;
        margin-bottom: 14px;
        padding: 13px;
        .content {
          width: 100%;
          padding-top: 18px;
          img{
            width: 60px;
            height: 12px;
          }
          .name{
            font-size: 13px;
          }
          .company{
            font-size: 13px;
          }
        }
        .case-title {
          width: 100%;
          font-size: 15px;
        }
      }
    }
  }
}
</style>