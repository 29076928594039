<template>
  <div>
    <!--  -->
    <div class="sponsor" v-if="sponsorList.length <= 0">
      <div class="sponsor-option">
        <p>金牌赞助</p>
      </div>
      <div class="sponsor-option">
        <p>银牌赞助</p>
      </div>
      <div class="sponsor-option">
        <p>铜牌赞助</p>
      </div>
      <div class="sponsor-option">
        <p>图书赞助</p>
      </div>
    </div>
    <div v-else class="sponsor" v-for="(sponsor, key) in splitSponsor(sponsorList)" :key="key">
      <div class="sponsor-option">
        <p>{{ key }}</p>
        <ul>
          <li v-for="(item, index) in sponsor" :key="index">
            <a v-if="item.content !== ''" :href="item.content === '' ? '#' : item.content" target="_blank">
              <img :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" />
            </a>
            <img v-else :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="sponsor" v-if="cooperatorList.length <= 0">
      <div class="sponsor-option">
        <p>合作媒体</p>
      </div>
      <div class="sponsor-option">
        <p>合作社区</p>
      </div>
      <!-- <div class="sponsor-option">
        <p>合作赞助</p>
      </div>
      <div class="sponsor-option">
        <p>独家人力资源合作伙伴</p>
      </div> -->
    </div>
    <div v-else class="sponsor" v-for="(cooperator, key) in splitCooperator(cooperatorList)" :key="'cooperator' + key">
      <div class="sponsor-option">
        <p>{{ key }}</p>
        <ul>
          <li v-for="(item, index) in cooperator" :key="index">
            <a v-if="item.content !== ''" :href="item.content === '' ? '#' : item.content" target="_blank">
              <img :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" />
            </a>
            <img v-else :src="item.file + '?imageView2/2/w/160/h/68/q/100'" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sponsorList: [],
      cooperatorList: [],
    };
  },
  mounted() {
    this.getSponsor();
    this.getCooperator();
  },
  methods: {
    getSponsor() {
      this.$http
        .jsonp("https://api.msup.com.cn/sue/get-all-sue-info/194")
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log("194 ", res);
          if (res.errno == 0) {
            this.sponsorList = res.data[0];
          }
        });
    },
    splitSponsor(list) {
      const newList = {
        金牌赞助: [],
        银牌赞助: [],
        铜牌赞助: [],
        图书赞助: [],
      };
      list.map((item) => {
        if (item.detailType === "134") {
          newList["金牌赞助"].push(item);
        } else if (item.detailType === "135") {
          newList["银牌赞助"].push(item);
        } else if (item.detailType === "136") {
          newList["铜牌赞助"].push(item);
        } else if (item.detailType === "137") {
          newList["图书赞助"].push(item);
        }
      });
      return newList;
    },
    getCooperator() {
      this.$http
        .jsonp("https://api.msup.com.cn/sue/get-all-sue-info/193")
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log("193 ", res);
          if (res.errno == 0) {
            this.cooperatorList = res.data[0];
          }
        });
    },
    splitCooperator(list) {
      const newList = {
        合作媒体: [],
        合作社区: [],
        // 合作赞助: [],
        // 独家人力资源合作伙伴: [],
      };
      list.map((item) => {
        if (item.detailType === "132") {
          newList["合作媒体"].push(item);
        } else if (item.detailType === "131") {
          newList["合作社区"].push(item);
        } else if (item.detailType === "130") {
          newList["合作赞助"].push(item);
        } else if (item.detailType === "133") {
          newList["独家人力资源合作伙伴"].push(item);
        }
      });
      return newList;
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsor {
  width: 1202px;
  margin: 0 auto;
  // padding-bottom: 42px;
  border: 1px solid #edf5fc;
  border-bottom: none;
  border-top: none;
  &:last-child {
    padding-bottom: 50px;
  }
  .sponsor-option {
    p {
      // padding-top: 70px;
      padding-top: 42px;
      padding-bottom: 42px;
      font-size: 24px;
      font-weight: 500;
      font-family: PingFang SC, PingFang SC-Regular;
      text-align: center;
      color: #003177;
    }
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        img {
          // height: 68px;
          // width: 180px;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .sponsor {
    width: 100vw;
    border: none;
    padding-bottom: 0;
    .sponsor-option {
      p {
        font-size: 15px;
        padding-top: 44px;
        padding-bottom: 29px;
      }
      ul {
        margin-bottom: 0;
        li {
          width: 33.33%;
          padding-left: 10px;
          padding-right: 10px;
          a {
            cursor: pointer;
          }
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .sponsor:last-child {
    padding-bottom: 53px;
  }
}
</style>